/* eslint-disable global-require */

export const initWOW = (): void => {
  if (typeof window !== `undefined`) {
    const { WOW } = require('wowjs')
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 50,
      mobile: true,
      live: true,
    })

    wow.init()
  }
}
