import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import NavBar from '@partials/NavBar'
import { initWOW } from '@helpers/Animate'

type Props = {
  children: React.ReactElement
}

const AppLayout: React.FC<Props> = ({ children }) => {
  useEffect(initWOW, [children])

  return (
    <div id="app">
      <Helmet>
        <html lang="en" />
      </Helmet>
      <div className="columns is-multiline app__container">
        <NavBar />
        {children}
      </div>
    </div>
  )
}

export default AppLayout
