import React, { HTMLProps } from 'react'
import { FadeAnimationClass } from 'types'

type Props = HTMLProps<HTMLImageElement> & {
  wowDelay?: number
  wowDuration?: number
  animate?: boolean
  fadeType?: FadeAnimationClass
  crossOrigin?: 'anonymous'
}

const Image: React.FC<Props> = ({
  src,
  alt,
  className = '',
  wowDelay = 0,
  wowDuration = 0,
  animate = false,
  fadeType = '',
  crossOrigin,
  ...restProps
}) => (
  <img
    className={`${className} ${animate ? 'wow' : ''} ${fadeType}`}
    data-wow-delay={`${wowDelay}s`}
    data-wow-duration={`${wowDuration}s`}
    src={src}
    alt={alt}
    crossOrigin="anonymous"
    {...restProps}
  />
)

export default Image
