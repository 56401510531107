import React, { ReactElement } from 'react'
import { Link as GatsbyLink } from 'gatsby'

type Props = React.HTMLAttributes<HTMLAnchorElement> & {
  href: string
  children: string | ReactElement
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  className?: string
  target?: '_blank'
}

const Link: React.FC<Props> = ({ href, children, onClick, className, target, ...restProps }) => (
  <GatsbyLink
    {...restProps}
    activeClassName="is--active"
    className={className}
    onClick={onClick}
    to={href}
    target={target}
  >
    {children}
  </GatsbyLink>
)

export default Link
