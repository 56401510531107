import React from 'react'
import Image from '@partials/Image'

import github from '@images/github.svg'
import linkedin from '@images/linkedin.svg'
import instagram from '@images/instagram.svg'
import '@sass/components/SocialLinks.scss'

const SocialLinks: React.FC = () => (
  <div className="social-links">
    <a href="https://github.com/EikerYejan/" target="_blank" rel="noopener noreferrer" title="Github">
      <Image src={github} alt="github-icon" />
    </a>
    <a href="https://linkedin.com/in/eikeryejan" target="_blank" rel="noopener noreferrer" title="Linkedin">
      <Image src={linkedin} alt="linkedin-icon" />
    </a>
    <a href="https://instagram.com/e.yejan/" target="_blank" rel="noopener noreferrer" title="Instagram">
      <Image src={instagram} alt="instagram-icon" />
    </a>
  </div>
)

export default SocialLinks
