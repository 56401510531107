import React from 'react'
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group'
import { motion } from 'framer-motion'
import { PageProps } from 'gatsby'

const timeout = 500
const transitionStyles: Record<string, Record<string, string | number>> = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const TransitionsHandler = ({ children, location }: PageProps) => {
  return (
    <TransitionGroup>
      <ReactTransition
        mountOnEnter
        onEnter={() => (typeof window !== `undefined` ? window.scrollTo(0, 0) : null)}
        key={location.pathname}
        timeout={timeout}
      >
        {(status) => (
          <motion.div
            transition={{ duration: 0.85 }}
            initial="exiting"
            variants={transitionStyles}
            animate={status}
            key={location.pathname}
          >
            {children}
          </motion.div>
        )}
      </ReactTransition>
    </TransitionGroup>
  )
}

export default TransitionsHandler
