/* eslint-disable react/no-array-index-key */
import React from 'react'
import { motion } from 'framer-motion'
import Link from '@partials/Link'
import SocialLinks from '@partials/SocialLinks'
import '@sass/components/Menu.scss'

type Props = {
  hideMenu: () => void
}

const styles = {
  initial: { opacity: 0, zIndex: -1, backdropFilter: 'blur(0px)' },
  entering: { opacity: 1, zIndex: 0, backdropFilter: 'blur(30px)' },
  transition: {
    duration: 0.35,
    type: 'tween',
  },
}

const Menu: React.FC<Props> = ({ hideMenu }) => (
  <motion.div
    transition={styles.transition}
    initial={styles.initial}
    animate={styles.entering}
    exit={styles.initial}
    className="nav-menu"
  >
    <ul>
      <li className="animated fadeIn delay-0-5s">
        <Link title="About" onClick={hideMenu} href="/about">
          About
        </Link>
      </li>
      <li className="animated fadeIn delay-0-8s">
        <Link title="Works" onClick={hideMenu} href="/works">
          Works
        </Link>
      </li>
      <li className="animated fadeIn delay-1s">
        <Link title="Contact" onClick={hideMenu} href="/contact">
          Contact
        </Link>
      </li>
    </ul>
    <div className="animated fadeIn delay-1s nav-menu__info">
      <span>Caracas, Venezuela</span>
      <p>
        <a href="mailto:eikeryejan@gmail.com" target="_blank" rel="noopener noreferrer" title="Email">
          eikeryejan@gmail.com
        </a>
      </p>
      <div className="social-links-wrapper">
        <SocialLinks />
      </div>
    </div>
    <ul className="background">
      {Array(10)
        .fill(0)
        .map((_, i) => (
          <li key={i} />
        ))}
    </ul>
  </motion.div>
)

export default Menu
